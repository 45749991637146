import spinboxTemplate from '../templates/spinbox.html';
;
(function (ng) {
  'use strict';

  angular.module('spinbox').directive('spinbox', ['$filter', function ($filter) {
    return {
      restrict: 'A',
      scope: {
        value: '=',
        proxy: '=?',
        min: '=?',
        max: '=?',
        step: '=?',
        updateFn: '&',
        validationText: '@',
        inputClasses: '<?',
        inputClassSize: '<?'
      },
      replace: true,
      bindToController: true,
      templateUrl: spinboxTemplate,
      controller: 'SpinboxCtrl',
      controllerAs: 'spinbox'
    };
  }]);
})(window.angular);